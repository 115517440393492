import React from 'react'

// Import typefaces
import 'typeface-montserrat'
import 'typeface-merriweather'

import profilePic from './profile-pic.jpg'
import { rhythm } from '../utils/typography'

class Bio extends React.Component {
  render() {
    return (
      <div
        style={{
          display: 'flex',
          marginBottom: rhythm(2.5),
        }}
      >
        <img
          src={profilePic}
          alt={`Nathan Bolser`}
          style={{
            marginRight: rhythm(1 / 2),
            marginBottom: 0,
            width: rhythm(2),
            height: rhythm(2),
          }}
        />
        <p>
          It's me... Nathan. Technical lead living in Denver, CO. Enjoys working
          with Ruby, Rails, Javascript, React, AWS. Also enjoys mountain biking,
          fly fishing, skiing, travel, and spending time with my Labrador
          Retriever, Bart.{' '}
        </p>
      </div>
    )
  }
}

export default Bio
